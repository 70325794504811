
:deep(.van-tab){
    font-size: 38px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #818080;
    line-height: 55px;
}
:deep(.van-tab--active){
    font-size: 40px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #06B2B2;
    line-height: 55px;
}
:deep(.van-tabs__line){
    background-color:#06B2B2;
}

